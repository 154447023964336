/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  SBTIdentityVerifier,
  SBTIdentityVerifierInterface,
} from "../SBTIdentityVerifier";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "previousAdmin",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "newAdmin",
        type: "address",
      },
    ],
    name: "AdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "beacon",
        type: "address",
      },
    ],
    name: "BeaconUpgraded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "version",
        type: "uint8",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "identityId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "senderAddr",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "tokenAddr",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenID",
        type: "uint256",
      },
    ],
    name: "SBTIdentityProved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "implementation",
        type: "address",
      },
    ],
    name: "Upgraded",
    type: "event",
  },
  {
    inputs: [],
    name: "SBT_IDENTITY_PROOF_QUERY_ID",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IZKPQueriesStorage",
        name: "zkpQueriesStorage_",
        type: "address",
      },
      {
        internalType: "contract IVerifiedSBT",
        name: "sbtToken_",
        type: "address",
      },
    ],
    name: "__SBTIdentityVerifier_init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "addressToIdentityId",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "schema_",
        type: "uint256",
      },
    ],
    name: "getAllowedIssuers",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "identityId_",
        type: "uint256",
      },
    ],
    name: "getIdentityProofInfo",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "senderAddr",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "sbtTokenId",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isProved",
            type: "bool",
          },
        ],
        internalType: "struct ISBTIdentityVerifier.SBTIdentityProofInfo",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "schema_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "issuerId_",
        type: "uint256",
      },
    ],
    name: "isAllowedIssuer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "userAddr_",
        type: "address",
      },
    ],
    name: "isIdentityProved",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "identityId_",
        type: "uint256",
      },
    ],
    name: "isIdentityProved",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "uint256",
                name: "issuerId",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "issuerState",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "createdAtTimestamp",
                type: "uint256",
              },
              {
                internalType: "bytes32[]",
                name: "merkleProof",
                type: "bytes32[]",
              },
            ],
            internalType: "struct ILightweightState.StatesMerkleData",
            name: "statesMerkleData",
            type: "tuple",
          },
          {
            internalType: "uint256[]",
            name: "inputs",
            type: "uint256[]",
          },
          {
            internalType: "uint256[2]",
            name: "a",
            type: "uint256[2]",
          },
          {
            internalType: "uint256[2][2]",
            name: "b",
            type: "uint256[2][2]",
          },
          {
            internalType: "uint256[2]",
            name: "c",
            type: "uint256[2]",
          },
        ],
        internalType: "struct IBaseVerifier.ProveIdentityParams",
        name: "proveIdentityParams_",
        type: "tuple",
      },
    ],
    name: "proveIdentity",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "proxiableUUID",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "sbtToken",
    outputs: [
      {
        internalType: "contract IVerifiedSBT",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IZKPQueriesStorage",
        name: "newZKPQueriesStorage_",
        type: "address",
      },
    ],
    name: "setZKPQueriesStorage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "uint256",
                name: "issuerId",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "issuerState",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "createdAtTimestamp",
                type: "uint256",
              },
              {
                internalType: "bytes32[]",
                name: "merkleProof",
                type: "bytes32[]",
              },
            ],
            internalType: "struct ILightweightState.StatesMerkleData",
            name: "statesMerkleData",
            type: "tuple",
          },
          {
            internalType: "uint256[]",
            name: "inputs",
            type: "uint256[]",
          },
          {
            internalType: "uint256[2]",
            name: "a",
            type: "uint256[2]",
          },
          {
            internalType: "uint256[2][2]",
            name: "b",
            type: "uint256[2][2]",
          },
          {
            internalType: "uint256[2]",
            name: "c",
            type: "uint256[2]",
          },
        ],
        internalType: "struct IBaseVerifier.ProveIdentityParams",
        name: "proveIdentityParams_",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "newIdentitiesStatesRoot",
            type: "bytes32",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "root",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "createdAtTimestamp",
                type: "uint256",
              },
            ],
            internalType: "struct ILightweightState.GistRootData",
            name: "gistData",
            type: "tuple",
          },
          {
            internalType: "bytes",
            name: "proof",
            type: "bytes",
          },
        ],
        internalType: "struct IBaseVerifier.TransitStateParams",
        name: "transitStateParams_",
        type: "tuple",
      },
    ],
    name: "transitStateAndProveIdentity",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "schema_",
        type: "uint256",
      },
      {
        internalType: "uint256[]",
        name: "issuerIds_",
        type: "uint256[]",
      },
      {
        internalType: "bool",
        name: "isAdding_",
        type: "bool",
      },
    ],
    name: "updateAllowedIssuers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newImplementation",
        type: "address",
      },
    ],
    name: "upgradeTo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newImplementation",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "upgradeToAndCall",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "zkpQueriesStorage",
    outputs: [
      {
        internalType: "contract IZKPQueriesStorage",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class SBTIdentityVerifier__factory {
  static readonly abi = _abi;
  static createInterface(): SBTIdentityVerifierInterface {
    return new utils.Interface(_abi) as SBTIdentityVerifierInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): SBTIdentityVerifier {
    return new Contract(address, _abi, signerOrProvider) as SBTIdentityVerifier;
  }
}
